.plans-section {
  margin-top: -4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plan-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  font-style: italic;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  .plan {
    display: flex;
    background-color: var(--caloryCard);
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    color: #fff;
    transition: all .2s ease-in-out;

    &:hover {
      background: var(--planCard);
      transform: scale(1.1);

      >svg {
        fill: #fff;
      }

      >button {
        color: var(--orange);
      }
    }

    >:nth-child(2) {
      font-size: 1rem;
      font-weight: bold;
    }

    >:nth-child(3) {
      font-size: 3rem;
      font-weight: bold;
    }

    >:nth-child(5) {
      font-size: 0.8rem;
    }

    >svg {
      fill: var(--orange);
      width: 2rem;
      height: 2rem;
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .feature {
        display: flex;
        align-items: center;
        gap: 1rem;

        >img {
          width: 1rem;
        }
      }
    }
  }
}


.p-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}

.p-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 15rem;
  right: 0rem;
}

@media screen and (max-width: 768px) {
  .plan-heading {
    flex-direction: column;
    font-size: x-large;
    justify-content: center;
    align-items: center;
  }

  .plans {
    flex-direction: column;

    &:hover {
      transform: scale(0.9);
    }
  }
}
