.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 8rem;
  height: 7rem;
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  color: #fff;

  >li {
    cursor: pointer;

    &:hover {
      color: var(--orange);
    }
  }

}

@media screen and (max-width: 768px) {
  .header {
    >:nth-child(2) {
      position: fixed;
      right: 2rem;
      z-index: 99;
    }

    .bars {
      background-color: var(--appColor);
      padding: 0.5rem;
      border-radius: 5px;
    }

    .bars-img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}
