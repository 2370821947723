.programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;

  .programs-heading {
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-size: 3rem;
    font-style: italic;
    color: #fff;
    font-weight: 700;
  }
}

.program-categories {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  gap: 1rem;


  .category {
    background-color: var(--gray);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;

    >:nth-child(1) {
      fill: #fff;
      width: 2rem;
      height: 2rem;
    }

    >span:nth-of-type(1) {
      font-weight: bold;
    }

    >span:nth-of-type(2) {
      line-height: 1.5;
    }
  }
}

.join {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;

  >img {
    width: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .programs .programs-heading {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .programs .program-categories {
    flex-direction: column;
  }
}
