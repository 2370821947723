.hero {
  display: flex;
  justify-content: space-between;
}

/* 使左側的空間加大，所以要控制左右的flex 比例 */
.left-h {
  padding: 2rem;
  padding-top: 1.5em;
  flex: 3 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.slogan {
  background-color: #393d42;
  border-radius: 4rem;
  color: #fff;
  /* 這裡的寬度 */
  width: fit-content;
  margin-top: 4rem;
  padding: 20px 13px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;

  >span {
    z-index: 2;
  }

  >div {
    position: absolute;
    z-index: 1;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    background-color: var(--orange);
    border-radius: 3rem;
  }
}



.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 4.5rem;
  font-weight: bold;
  color: #fff;
  text-overflow: inherit;

  >div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
    width: 80%;
  }
}

.figure {
  display: flex;
  gap: 1.5rem;

  >div {
    display: flex;
    flex-direction: column;

    >span:nth-of-type(1) {
      color: #fff;
      font-size: 2rem;
    }

    >span:nth-of-type(2) {
      color: var(--gray);
    }
  }
}



.hero-btns {
  display: flex;
  gap: 1rem;
  font-weight: normal;

  >:nth-child(1) {
    color: #fff;
    background-color: var(--orange);
    width: 8rem;
  }

  >:nth-child(2) {
    color: #fff;
    background-color: transparent;
    border: 2px solid var(--orange);
    width: 8rem;
  }
}


.right-h {
  flex: 1 1;
  position: relative;
  background-color: var(--orange);

  >.btn {
    color: #393d42;
    position: absolute;
    right: 3rem;
    top: 2rem;
  }
}

.heart-rate {
  background-color: #393d42;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 1rem;
  border-radius: 5px;
  position: absolute;
  gap: 1rem;
  right: 4rem;
  top: 7rem;

  >img {
    width: 2rem;
  }

  >span:nth-of-type(1) {
    color: var(--gray);
  }

  >span:nth-of-type(2) {
    color: #fff;
    font-size: 1.5rem;
  }
}

.hero-img {
  position: absolute;
  width: 33rem;
  top: 10rem;
  right: 8rem;
}

.hero-img-back {
  position: absolute;
  width: 15rem;
  top: 4rem;
  right: 20rem;
  z-index: -1;
}

.calories-img {
  background-color: var(--caloryCard);
  position: absolute;
  width: 15rem;
  top: 32rem;
  right: 29rem;
  z-index: -1;
  display: flex;
  padding: 1rem;
  width: fit-content;
  gap: 1.5rem;
  border-radius: 5px;

  >div {
    display: flex;
    flex-direction: column;

    >span:nth-of-type(1) {
      color: var(--gray);
    }

    >span:nth-of-type(2) {
      color: #fff;
      font-size: 1.5rem;
    }
  }
}

.blur-hero {
  width: 22rem;
  height: 30rem;
  right: 36px;
  top: 49px;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .blur-hero {
    width: 13rem;
  }

  .slogan {
    margin-top: 0;
    font-size: small;
    align-items: center;

  }

  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;

    >div:nth-of-type(3) {
      font-size: small;
      font-weight: 200;
      text-align: center;
      letter-spacing: 0.8px;
    }

    .hero-btns {
      justify-content: center;
    }
  }

  .figure {
    >div {

      >span:nth-of-type(1) {
        font-size: large;
      }

      >span:nth-of-type(2) {
        font-size: small;
      }
    }
  }

  .right-h {
    position: relative;
    background: none;

    .heart-rate {
      left: 1rem;
      top: 2rem;
      z-index: -2;
    }

    .calories-img {
      position: relative;
      top: 5rem;
      left: 2rem;

      >img {
        width: 2rem;
      }

      >div> :nth-child(2) {
        color: #fff;
        font-size: 1rem;

      }
    }

    .hero-img {
      position: relative;
      width: 15rem;
      left: 7rem;
      top: 10rem;
      align-self: center;
    }
  }
}
