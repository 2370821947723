.testimonial {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;

  .left-t {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: #fff;
    gap: 2rem;

    >:nth-child(1),
    >:nth-child(2) {
      font-size: 3rem;
      font-weight: bold;
    }

    >:nth-child(3) {
      text-transform: none;
      text-align: justify;
      letter-spacing: 1.5px;
      line-height: 40px;
    }
  }
}

.t-name {
  color: var(--orange);
}

.right-t {
  flex: 1;
  position: relative;

  >:nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    background-color: transparent;
    border: 2px solid rgb(191, 149, 71);
    right: 9rem;
    top: 0.9rem;
  }

  >:nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    right: 6rem;
    top: 4rem;
  }

  >img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 7rem;
    top: 2rem;

  }
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;

  >img {
    width: 1.5rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .testimonial {
    flex-direction: column;
  }

  // .left-t {
  //   >:nth-child(2) {}

  //   >:nth-child(3) {}
  // }

  .right-t {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    >div {
      position: relative;
      display: none;
    }

    >img {
      top: 0;
      right: 0;
      position: relative;
      align-self: center;
    }

    >:last-child {
      display: block;
      bottom: 0;
      left: 0;
    }

    .arrows {
      >img {
        padding: 1rem;
      }
    }
  }
}
